import React from "react";
import StyledSpan from "./../../commons/StyledSpan";
import { getDateTime } from "../../helpers/dateTime";

function CustomerRowItem({ item, user, sn, onClick = () => {} }) {
  return (
    <>
      <tr class="hover:bg-gray-100 cursor-pointer" onClick={onClick}>
        {/* <td
          scope="row"
          class="px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
        >
          <input type="checkbox" />
        </td> */}
        {/* {sn && <td class="px-6 py-4 text-[13px]">{sn}</td>} */}
        <td scope="row" class="px-6 py-4 text-[14px] font-[400]">
          #{item?.id}
        </td>
        <td class="px-6 py-4 text-[14px] font-[400]">
          {item?.firstName + " " + item?.lastName}
        </td>
        <td class="px-6 py-4 text-[13px]">{item?.email}</td>
        <td class="px-6 py-4 text-[14px] font-[400]">{item?.phoneNumber}</td>
        <td class="px-6 py-4 flex gap-3">
          <StyledSpan text={item?.loginStatus} />
        </td>
        <td class="px-6 py-4 text-[14px] font-[400]">
          {getDateTime(item?.createdAt)}
        </td>
        {/* <td class="px-6 py-4">
          <div className="flex gap-3">
            <div className="text-[#318EC9] cursor-pointer">View</div>
            <div className="text-[#008000] cursor-pointer">Edit</div>
          </div>
        </td> */}
      </tr>
    </>
  );
}

export default CustomerRowItem;
