import React from "react";
import CustomIconLabelValue from "../../commons/CustomIconLabelValue";
import EmailIcon from "../../commons/EmailIcon";
import LocationIcon from "../../commons/LocationIcon";
import PhoneIcon from "../../commons/PhoneIcon";
import StyledSpan from "../../commons/StyledSpan";
import UserIcon from "../../commons/UserIcon";
import { getDateTime } from "../../helpers/dateTime";

function AccountInfo({
  name = "",
  topText = "",
  ownerLabel = "",
  email = "",
  phoneNumber = "",
  imagePath = "/assets/user-icon-orange.png",
  address = "",
  status = "",
  lastLoginDate = "",
  loginType = "",
}) {
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div>
            <img
              alt=""
              className="w-[3em] h-[3em] rounded-full border shadow-sm"
              src={imagePath}
              style={{ objectFit: "contain" }}
            />
          </div>
          <div className="text-[14px]">{topText}</div>
        </div>
        <div>
          <StyledSpan text={status} />
        </div>
      </div>
      <hr className="my-5" />
      <div className="flex flex-col w-full gap-3">
        {ownerLabel && (
          <CustomIconLabelValue
            label={ownerLabel}
            value={name}
            icon={<UserIcon className="text-[18px]" />}
          />
        )}
        {email && (
          <CustomIconLabelValue
            label="E-mail"
            value={email}
            icon={<EmailIcon className="text-[18px]" />}
          />
        )}
        {address && (
          <CustomIconLabelValue
            label="Address"
            value={address}
            icon={<LocationIcon className="text-[18px]" />}
          />
        )}
        {phoneNumber && (
          <CustomIconLabelValue
            label="Phone Number"
            value={phoneNumber}
            icon={<PhoneIcon className="text-[18px]" />}
          />
        )}
        {status && (
          <CustomIconLabelValue
            label="Status"
            value={status}
            icon={<PhoneIcon className="text-[18px]" />}
          />
        )}
        {lastLoginDate && (
          <CustomIconLabelValue
            label="Last Login Date"
            value={getDateTime(lastLoginDate)}
            icon={<PhoneIcon className="text-[18px]" />}
          />
        )}
        {loginType && (
          <CustomIconLabelValue
            label="Login Type"
            value={`${loginType} User`}
            icon={<PhoneIcon className="text-[18px]" />}
          />
        )}
      </div>
    </div>
  );
}

export default AccountInfo;
