import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const fetchUserAddresses = async (data) => {
  return axiosInstance(
    `/addresses/user/${data.id}`,
    "GET",
    data,
    data.accessToken
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const fetchAddress = async (data) => {
  return axiosInstance(`/addresses/${data.id}`, "GET", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const addNewAddress = async (data) => {
  return axiosInstance(`/addresses`, "POST", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const updateUserAddress = async (data) => {
  return axiosInstance(
    `/addresses/user/${data.id}`,
    "PUT",
    data,
    data.accessToken
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const deleteUserAddress = async (data) => {
  return axiosInstance(
    `/addresses/user/${data.id}`,
    "DELETE",
    data,
    data.accessToken
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};
