import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { ORDER_ITEMS_QUERY_KEY } from "../constants/queryKeys";
import { fetchAllOrderItems } from "../services/order-services";

function useAllOrders(
  user,
  pageSize,
  pageNumber,
  filter,
  dateRange,
  searchTerm
) {
  const navigate = useNavigate();
  const {
    data: orderItems,
    isLoading,
    error,
  } = useQuery({
    queryKey: [
      ORDER_ITEMS_QUERY_KEY,
      pageSize,
      pageNumber,
      filter,
      dateRange,
      searchTerm,
    ],
    queryFn: async () => {
      const result = await fetchAllOrderItems(
        user,
        pageSize,
        pageNumber,
        filter,
        dateRange,
        searchTerm
      );
      return result?.data?.data;
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    orderItems,
    isLoading,
    error,
  };
}

export default useAllOrders;
