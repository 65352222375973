import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { SUPPORT_TICKETS_QUERY_KEY } from "../constants/queryKeys";
import { getSupportTickets } from "../services/contact-requests-services";

function useSupportTickets(data) {
  const navigate = useNavigate();
  const {
    data: supportTickets,
    isLoading,
    error,
  } = useQuery({
    queryKey: [
      SUPPORT_TICKETS_QUERY_KEY,
      data?.status,
      data?.searchTerm,
      data?.dateRange,
    ],
    queryFn: async () => {
      const result = await getSupportTickets(data);
      return result?.data?.data;
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    supportTickets,
    isLoading,
    error,
  };
}

export default useSupportTickets;
