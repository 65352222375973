import { useMutation } from "@tanstack/react-query";
import { exportReturnRequests } from "../services/exports-services";

function useExportReturnRequests() {
  const {
    mutateAsync: returnRequestDataExport,
    isLoading,
    error,
  } = useMutation({
    mutationFn: async (data) => exportReturnRequests(data),
    onSuccess: (result) => {
      return result;
    },
    onError: (error) => {},
  });
  return {
    returnRequestDataExport,
    isLoading,
    error,
  };
}

export default useExportReturnRequests;
