import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteUserAddress } from "../services/address-services";
import { CUSTOMER_QUERY_KEY } from "../constants/queryKeys";

function useDeleteAddress() {
  const queryClient = useQueryClient();
  const {
    mutateAsync: deleteAddress,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (addressData) => deleteUserAddress(addressData),
    onSuccess: (data) => {
      queryClient.invalidateQueries([CUSTOMER_QUERY_KEY]);
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    deleteAddress,
    isLoading,
    error,
    data,
  };
}

export default useDeleteAddress;
