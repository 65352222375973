export const getNetworkErrorMessage = (err) => {
  try {
    console.log("you have an error", err);
    if (err?.response?.data?.message) {
      throw Error(err?.response?.data?.message);
    } else if (
      err?.response?.data?.errors?.name == "SequelizeUniqueConstraintError"
    ) {
      throw Error(
        err?.response?.data?.errors?.errors[0]?.message ?? err?.message
      );
    } else if (err?.code === "ERR_BAD_REQUEST") {
      throw Error(err?.response?.data?.message ?? err?.response?.statusText);
    } else if (err?.code === "ERR_NETWORK") {
      throw Error(err?.message);
    } else if (err?.code === "ERR_BAD_RESPONSE") {
      throw Error(err?.message);
    } else {
      throw new Error(err);
    }
  } catch (err) {
    throw new Error(err?.message ?? "An error occurred. Please try again!!!");
  }
};
