import React, { useState } from "react";
import CustomIconLabelValue from "../../commons/CustomIconLabelValue";
import GeneralModal from "../../commons/GeneralModal";
import DocumentIcon from "../../commons/DocumentIcon";
import CardTitle from "../admin_commons/CardTitle";
import DownloadIcon from "../../commons/DownloadIcon";

function VendorDocuments({ cacDocumentPath, taxDocumentPath }) {
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileView = (url) => {
    setSelectedFile(url);
    setShowFileViewer(true);
  };

  return (
    <>
      <div>
        <CardTitle title="Documents" />
        <div>
          <div>
            {cacDocumentPath && (
              <>
                <div className="mb-6"></div>
                <CustomIconLabelValue
                  label={"CAC Document"}
                  value={
                    <div className="flex gap-3 items-center">
                      <span
                        className="text-green-700 text-[500]"
                        onClick={() => handleFileView(cacDocumentPath)}
                      >
                        View
                      </span>
                      <a
                        target="_blank"
                        href={cacDocumentPath}
                        download
                        className=""
                      >
                        <DownloadIcon
                          className={
                            "text-[20px] text-[#828282] cursor-pointer"
                          }
                        />
                      </a>
                    </div>
                  }
                  icon={<DocumentIcon>Download</DocumentIcon>}
                />
              </>
            )}

            {taxDocumentPath && (
              <>
                <div className="mb-4"></div>
                <CustomIconLabelValue
                  label={"ID Document"}
                  value={
                    <div className="flex gap-3 items-center">
                      <span
                        className="text-green-700 text-[500]"
                        onClick={() => handleFileView(taxDocumentPath)}
                      >
                        View
                      </span>
                      <a
                        target="_blank"
                        href={taxDocumentPath}
                        download
                        className=""
                      >
                        <DownloadIcon
                          className={
                            "text-[20px] text-[#828282] cursor-pointer"
                          }
                        />
                      </a>
                    </div>
                  }
                  icon={<DocumentIcon>Download</DocumentIcon>}
                />
              </>
            )}
          </div>
        </div>
      </div>

      {/* Modal to show file */}
      <GeneralModal
        showCloseButton={true}
        isOpen={showFileViewer}
        onClose={() => setShowFileViewer(false)}
      >
        <div className="h-[40em] text-center">
          <iframe
            src={selectedFile}
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen
            title="Media Display"
            style={{ border: "none" }}
          >
            Your browser does not support iframes.
          </iframe>
        </div>
      </GeneralModal>
    </>
  );
}

export default VendorDocuments;
