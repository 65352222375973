import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const newContactRequest = async (data) => {
  return axiosInstance(`/contacts`, "POST", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const getSupportTickets = async (data) => {
  return axiosInstance(
    `/contacts?status=${data?.status}&searchTerm=${data?.searchTerm}&dateRange=${data?.dateRange}`,
    "GET",
    data,
    data.accessToken
  ).then((response) => {
    return response;
  });
};

export const getSupportTicket = async (data) => {
  return axiosInstance(`/contacts/${data.id}`, "GET", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const updateTicketStatus = async (data) => {
  let url = `/contacts/${data.id}/status`;

  return axiosInstance(url, "PUT", data, data.token)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};
