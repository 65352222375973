import React from "react";
import CustomPriceDisplay from "../../commons/CustomPriceDisplay";
import StyledSpan from "../../commons/StyledSpan";
import { getDateTime } from "./../../helpers/dateTime";

function ProductRowItem({ item, user, onClick = () => {} }) {
  return (
    <>
      <tr
        class="hover:bg-gray-100 cursor-pointer items-center"
        onClick={onClick}
      >
        {/* <td
          scope="row"
          class="px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
        >
          <input type="checkbox" />
        </td> */}
        <td scope="row" class="px-6 py-4 whitespace-nowrap text-[14px]">
          <div className="flex gap-2 items-center">
            <img
              className="w-[3em] h-[3em] rounded-lg"
              style={{ objectFit: "contain" }}
              src={item?.imageOne}
            />
            <span>{item?.name}</span>
          </div>
        </td>
        <td class="px-6 py-4 text-[14px]">
          {item?.Type?.Subcategory?.Category?.name}
        </td>
        <td class="px-6 py-4 text-[14px] font-[400]">{item?.sku}</td>
        <td class="px-6 py-4 text-[14px] font-[400]">{item?.quantity}</td>
        <td class="px-6 py-4 text-[14px] font-[400]">
          <CustomPriceDisplay price={item?.price} />
        </td>
        <td class="px-6 py-4 text-[14px] font-[400]">
          {getDateTime(item?.createdAt, "DD/MM/YY")}
        </td>
        <td class="px-6 py-4 gap-3">
          <StyledSpan text={item?.status} />
        </td>
      </tr>
    </>
  );
}

export default ProductRowItem;
