import {
  ACTIVE,
  COMPLETED,
  DRAFT,
  FAILED,
  INACTIVE,
  OPEN,
  PENDING,
  REJECTED,
  RETURNED,
  SUCCESSFUL,
} from "../constants/texts";
import { CLOSED } from "./../constants/texts";

export const getColorArray = (string) => {
  if (!string) {
    return [];
  } else {
    const arr = string.split(",");
    return arr;
  }
};

export const addPaginationUrlQuery = (originalUrl, pageSize, pageNumber) => {
  let url = originalUrl;
  let others = "";

  if (pageSize) {
    others += "pageSize=" + pageSize;
  }

  if (pageNumber) {
    if (others.length > 0) {
      others += "&";
    }
    others += "pageNumber=" + pageNumber;
  }

  if (others.length > 0) {
    url = url + "?" + others;
  }

  return url;
};

export const getFileUrl = (imagePath) => {
  if (imagePath) {
    return process.env.REACT_APP_BACKEND_URL_PATH + "/uploads/" + imagePath;
  }

  return "";
};

export const getMaxValue = (arr, key) => {
  let newArray = [];

  if (typeof arr[0] == "object") {
    newArray = arr.map((a) => parseInt(a[key]));
  } else {
    newArray = arr.map((a) => parseInt(a));
  }

  return newArray.reduce((a, b) => {
    return Math.max(a, b);
  });
};

export const addOrRemoveFromList = (item, list, addOrRemove) => {
  if (addOrRemove === 1) {
    if (!list.includes(item)) {
      list.push(item);
    }
  } else {
    var index = list.indexOf(item);
    if (index > -1) {
      list.splice(index, 1);
    }
  }
  return list;
};

// Classes to be applied 0 = orange, 1 = green, 2 = red
export const orangeClasses = "bg-orange-400 text-white";
export const greenClasses = "bg-green-700 text-white";
export const redClasses = "bg-red-500 text-white";
export const grayClasses = "bg-gray-100 text-black";

export const getColorStatus = (status) => {
  if (status == 1) {
    return 1;
  }

  if (status == 0) {
    return 0;
  }

  if (status == 2) {
    return 2;
  }

  if (status?.toLowerCase()?.includes(PENDING.toLowerCase())) {
    return 0;
  }

  if (status?.toLowerCase()?.includes(OPEN.toLowerCase())) {
    return 0;
  }

  if (status?.toLowerCase()?.includes(INACTIVE.toLowerCase())) {
    return 3;
  }

  if (
    status?.toLowerCase()?.includes(ACTIVE.toLowerCase()) ||
    status?.toLowerCase()?.includes(SUCCESSFUL.toLowerCase()) ||
    status?.toLowerCase()?.includes(COMPLETED.toLowerCase()) ||
    status?.toLowerCase()?.includes(RETURNED.toLowerCase())
  ) {
    return 1;
  }

  if (status?.toLowerCase()?.includes(CLOSED.toLowerCase())) {
    return 1;
  }
  if (status?.toLowerCase()?.includes(DRAFT.toLowerCase())) {
    return 3;
  }

  if (status?.toLowerCase()?.includes(FAILED.toLowerCase())) {
    return 2;
  }
  if (status?.toLowerCase()?.includes(REJECTED.toLowerCase())) {
    return 2;
  }

  return 1;
};

export const appliedClasses = (classIndex) => {
  switch (classIndex) {
    case 0:
      return orangeClasses;
    case 1:
      return greenClasses;
    case 2:
      return redClasses;
    case 3:
      return grayClasses;
    default:
      return grayClasses;
  }
};

export const getFullName = (a, b) => {
  return a + " " + b;
};

export const getFullAddress = (address, region, state) => {
  return address + ", " + region + ", " + state;
};

export function getSelectedTab(tab) {
  if (tab.toLowerCase() == "all") {
    return "";
  } else {
    return tab;
  }
}
