import { addPaginationUrlQuery } from "../helpers/extractors";
import axiosInstance from "../utils/axiosInstance";
import { getNetworkErrorMessage } from "./../helpers/network";

export const getVendors = async (data) => {
  let url = "/vendors";
  url = addPaginationUrlQuery(url, data?.pageSize, data?.pageNumber);

  return axiosInstance(
    `${url}&status=${data?.status}&searchTerm=${data?.searchTerm}&dateFrom=${data?.dateRange[0]}&dateTo=${data?.dateRange[1]}`,
    "GET",
    data,
    data?.accessToken
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const getVendor = async (data) => {
  return axiosInstance(`/vendors`, "POST", data, data.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const getVendorPaymentSplit = async (data) => {
  return axiosInstance(
    `/payment-splits/owners/${data?.id}`,
    "GET",
    data,
    data.accessToken
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const createPaymentSplit = async (data) => {
  return axiosInstance(`/payment-splits`, "POST", data, data?.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const modifyPaymentSplit = async (data) => {
  return axiosInstance(
    `/payment-splits/${data?.guid}`,
    "PUT",
    data,
    data?.accessToken
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const vetVendor = async (data) => {
  return axiosInstance(`/vendors/vet-business`, "POST", data, data?.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const vetUnregisteredVendor = async (data) => {
  return axiosInstance(
    `/vendors/vet-unregistered-business`,
    "POST",
    data,
    data?.accessToken
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const updateVendor = async (data) => {
  return axiosInstance(`/vendors/${data.id}`, "PUT", data, data?.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const reviewVendor = async (data) => {
  return axiosInstance(
    `/vendors/${data.id}/review`,
    "PUT",
    data,
    data?.accessToken
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};
