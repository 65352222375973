import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CUSTOMER_QUERY_KEY } from "../constants/queryKeys";
import { updateUserAddress } from "../services/address-services";

function useUpdateAddress() {
  const queryClient = useQueryClient();
  const {
    mutateAsync: updateAddress,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (addressData) => updateUserAddress(addressData),
    onSuccess: (data) => {
      queryClient.invalidateQueries([CUSTOMER_QUERY_KEY]);
    },
    onError: (error) => {
      // return error
    },
  });
  return {
    updateAddress,
    isLoading,
    error,
    data,
  };
}

export default useUpdateAddress;
