import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_QUERY_KEY } from "../constants/queryKeys";
import { getDashboardData } from "../services/dashboard-services";

function useDashboardData(data, filter) {
  const navigate = useNavigate();
  const {
    data: dashboardData,
    isLoading,
    error,
  } = useQuery({
    queryKey: [DASHBOARD_QUERY_KEY, filter],
    queryFn: async () => {
      const result = await getDashboardData(data, filter);
      return result?.data;
    },
  });
  return {
    dashboardData,
    isLoading,
    error,
  };
}

export default useDashboardData;
