import React, { useState } from "react";
import CardTitle from "../admin_commons/CardTitle";
import { Form, Formik } from "formik";
import useUser from "../../hooks/useUser";
import useUpdateAddress from "../../hooks/useUpdateAddress";
import CustomInputField from "../../commons/CustomInputField";
import FullRoundedButton from "../../commons/FullRoundedButton";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import CustomSnackBar from "../../commons/CustomSnackBar";
import CustomTextField from "../../commons/CustomTextField";
import useDeleteAddress from "../../hooks/useDeleteAddress";
import ConfirmationModal from "../../commons/modals/ConfirmationModal";
import CustomLabelValue from "../../commons/CustomLabelValue";

const CustomerEditAddress = ({ address, onClose, customer }) => {
  const { user } = useUser();

  const { updateAddress } = useUpdateAddress();
  const { deleteAddress, isLoading: isDeleting } = useDeleteAddress();

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteClick = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDeleteAddress = (data) => {
    deleteAddress(data)
      .then((res) => {
        setSnackBarMessage("Address deleted successfully");
        setIsError(false);
        setOpenSnackBar(true);
        setTimeout(() => {
          handleCloseDeleteModal();
          onClose();
        }, 1500);
      })
      .catch((err) => {
        setSnackBarMessage(err?.message);
        setIsError(true);
        setOpenSnackBar(true);
      })
      .finally(() => {});
  };

  return (
    <div className="shadow-md rounded-lg w-[100%] bg-white p-6 text-left">
      <div className="flex justify-between items-start pb-4">
        <div className="">
          <CardTitle title={`Edit ${customer?.firstName}'s Address`} />
        </div>
      </div>

      {/* Form */}
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            id: address?.id,
            firstName: address?.firstName,
            lastName: address?.lastName,
            phoneNumber: address?.phoneNumber,
            address: address?.address,
            city: address?.city,
            zipCode: address?.zipCode,
            email: address?.email,
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            updateAddress({
              ...values,
              userId: customer?.id,
              accessToken: user?.token,
            })
              .then((res) => {
                setSnackBarMessage("Address updated successfully");
                setIsError(false);
                setOpenSnackBar(true);
                setTimeout(() => {
                  onClose();
                }, 1500);
              })
              .catch((err) => {
                setSnackBarMessage(err?.message);
                setIsError(true);
                setOpenSnackBar(true);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Email is required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.lastName) {
              errors.lastName = "Last name is required";
            }
            if (!values.firstName) {
              errors.firstName = "First name is required";
            }
            if (!values.phoneNumber) {
              errors.phoneNumber = "Mobile number is required";
            }
            if (!values.address) {
              errors.address = "Address is required";
            }
            if (!values.zipCode) {
              errors.zipCode = "Zip code is required";
            }
            if (!values.city) {
              errors.city = "City is required";
            }
            return errors;
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="flex max-md:flex-col items-center justify-between gap-4 max-md:gap-0">
                <div className="w-1/2 max-md:w-full">
                  <CustomInputField
                    label={"First Name"}
                    type="text"
                    placeholder="Please enter your first name"
                    name="firstName"
                    disabled={isSubmitting}
                  />
                </div>
                <div className="w-1/2 max-md:w-full">
                  <CustomInputField
                    label={"Last Name"}
                    type="text"
                    placeholder="Please enter your last name"
                    name="lastName"
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              <CustomTextField
                label={"Address"}
                type="text"
                placeholder="Please enter your address"
                name="address"
                disabled={isSubmitting}
              />

              {/* City and Zip code */}
              <div className="flex max-md:flex-col items-center justify-between gap-4 max-md:gap-0">
                <div className="w-1/2 max-md:w-full">
                  <CustomInputField
                    label={"City/Town"}
                    type="text"
                    placeholder="Please enter your city/town"
                    name="city"
                    disabled={isSubmitting}
                  />
                </div>
                <div className="w-1/2 max-md:w-full">
                  <CustomInputField
                    label={"Zip Code"}
                    type="text"
                    placeholder="Please enter your zip code"
                    name="zipCode"
                    disabled={isSubmitting}
                  />
                </div>
              </div>

              {/* Mobile Number and Email Address */}
              <div className="flex max-md:flex-col items-center justify-between gap-4 max-md:gap-0">
                <div className="w-1/2 max-md:w-full">
                  <CustomInputField
                    label={"Mobile Number"}
                    type="text"
                    placeholder="Please enter your mobile number"
                    name="phoneNumber"
                    disabled={isSubmitting}
                  />
                </div>
                <div className="w-1/2 max-md:w-full">
                  <CustomInputField
                    label={"Email Address"}
                    type="email"
                    placeholder="Please enter your email address"
                    name="email"
                    disabled={isSubmitting}
                  />
                </div>
              </div>

              <div className="flex gap-6">
                {/* Submit button */}
                <FullRoundedButton
                  label={isSubmitting ? "Please wait" : "Update address"}
                  className={"text-white bg-[#FF9D21] hover:bg-[#FF9D21] mt-3"}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <CustomThreeDotsSpinner
                      height="20"
                      width="20"
                      color="white"
                    />
                  )}
                </FullRoundedButton>
                <FullRoundedButton
                  onClick={handleDeleteClick}
                  label={isSubmitting ? "Please wait" : "Delete address"}
                  className={"text-white bg-red-500 hover:bg-red-600 mt-3"}
                  type="button"
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <CustomThreeDotsSpinner
                      height="20"
                      width="20"
                      color="white"
                    />
                  )}
                </FullRoundedButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="mb-10 max-md:gap-5"></div>

      {/* Modal to delete address */}
      <ConfirmationModal
        isOpen={openDeleteModal}
        onClose={handleCloseDeleteModal}
        showCloseButton={true}
        description="Are you sure you want to delete the following address?"
        title="Delete Address"
      >
        <>
          <CustomLabelValue label="First name" value={address?.firstName} />
          <CustomLabelValue label="Last name" value={address?.lastName} />
          <CustomLabelValue label="Address" value={address?.address} />
          <CustomLabelValue label="Email" value={address?.email} />
          <CustomLabelValue
            label="Mobile Number"
            value={address?.phoneNumber}
          />
          <CustomLabelValue label="City/Town" value={address?.city} />
          <CustomLabelValue label="Zip code" value={address?.zipCode} />

          <div className="text-right flex justify-end mt-12 gap-3">
            <FullRoundedButton
              label="Cancel"
              type="button"
              className="bg-[#D0D5DD]"
              onClick={handleCloseDeleteModal}
              disabled={isDeleting}
            />
            <FullRoundedButton
              label={isDeleting ? "Please wait" : "Delete address"}
              type="button"
              className="bg-[#FF0000] text-white"
              disabled={isDeleting}
              onClick={() =>
                handleDeleteAddress({
                  id: address?.id,
                  userId: customer?.id,
                  accessToken: user?.accessToken,
                })
              }
            />
          </div>
        </>
      </ConfirmationModal>

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
    </div>
  );
};

export default CustomerEditAddress;
