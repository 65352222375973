import React, { useState } from "react";
import { Formik } from "formik";
import { Form } from "formik";
import CustomInputField from "../../commons/CustomInputField";
import { imageAndDocFileTypes } from "../../constants/data";
import ConfirmationModal from "../../commons/modals/ConfirmationModal";
import RoundedMdButton from "../../commons/RoundedMdButton";
import { getFileTypesFriendly } from "../../helpers/fileUpload";
import { validEmail, validPhoneNumber } from "../../helpers/validator";
import useCustomInfoDisplay from "../../hooks/useCustomInfoDisplay";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../commons/PageTitle";
import CustomSelectField from "./../../commons/CustomSelectField";
import { ADMIN_ROLES } from "../../constants/roles";
import { GENERAL_ORANGE_HEX } from "../../constants/texts";
import CustomButton from "../../commons/CustomButton";
import CustomTextField from "../../commons/CustomTextField";
import { delayAction } from "../../helpers/events";

function UserDetails({ onClose, callback, isEditing, selectedUser, onSubmit }) {
  const navigate = useNavigate();

  const { setInfoDetails, InfoDisplayComponent } = useCustomInfoDisplay();
  const [openReviewModal, setOpenReviewModal] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showReasonInput, setShowReasonInput] = useState(false);
  const [comment, setComment] = useState("");

  const handleReview = (approveOrReject, data) => {
    let action = approveOrReject;

    if (action == false && !data?.comment) {
      setInfoDetails({
        message: "Please enter reason for deactivation",
        isError: true,
      });
      setIsSubmitting(false);
      return;
    }

    //continue with submission
    setIsSubmitting(true);

    onSubmit(
      { ...data, action },
      () => {
        delayAction(callback, 2000);
      },
      () => {
        setIsSubmitting(false);
        setOpenReviewModal(false);
        setShowReasonInput(false);
        setComment("");
      },
      true
    );
  };

  return (
    <div className="m">
      <div className="flex items-center justify-between">
        <PageTitle title={isEditing ? "Edit User" : "Add User"} />
        {isEditing && (
          <CustomButton
            label="Review"
            onClick={() => setOpenReviewModal(true)}
            className={`border text-white border-[${GENERAL_ORANGE_HEX}] bg-[${GENERAL_ORANGE_HEX}]`}
          />
        )}
      </div>
      <hr className="my-4" />
      <div className="mt-2"></div>
      <Formik
        enableReinitialize={isEditing}
        initialValues={{
          id: selectedUser?.id ?? "",
          role: selectedUser?.role ?? "",
          newRole: selectedUser?.role ?? "",
          email: selectedUser?.email ?? "",
          phoneNumber: selectedUser?.phoneNumber ?? "",
          firstName: selectedUser?.firstName ?? "",
          lastName: selectedUser?.lastName ?? "",
          comment: selectedUser?.comment ?? "",
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          onSubmit(
            {
              ...values,
            },
            resetForm,
            () => setSubmitting(false)
          );
        }}
        validate={(values) => {
          const errors = {};

          if (!values.email) {
            errors.email = "Email is required";
          } else if (!validEmail(values.email)) {
            errors.email = "Invalid email address";
          }

          if (!values.phoneNumber) {
            errors.phoneNumber = "Phone number is required";
          } else if (!validPhoneNumber(values.phoneNumber)) {
            errors.phoneNumber = "Invalid phone number";
          }
          if (!values.firstName) {
            errors.firstName = "First name is required";
          }

          if (!values.lastName) {
            errors.lastName = "Last name is required";
          }

          if (!values.role) {
            errors.role = "Role is required";
          }

          return errors;
        }}
      >
        {({ isSubmitting, setFieldValue, setFieldError, values }) => (
          <Form>
            <div className="flex items-start gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"firstName"}
                  required={true}
                  placeholder="Enter first name"
                  label={"First Name"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"lastName"}
                  required={true}
                  placeholder="Enter last name"
                  label={"Last Name"}
                />
              </div>
            </div>
            <div className="flex items-start gap-4">
              <div className="w-full">
                <CustomInputField
                  name={"phoneNumber"}
                  required={true}
                  placeholder="Enter phone number"
                  label={"Phone Number"}
                />
              </div>
              <div className="w-full">
                <CustomInputField
                  name={"email"}
                  required={true}
                  placeholder="Enter your email"
                  label={"Email"}
                />
              </div>
            </div>

            <div className="flex items-start gap-6 mt-1">
              <div className="w-full">
                <CustomSelectField
                  name={"role"}
                  required={true}
                  list={ADMIN_ROLES}
                  selectedValue={values.role}
                  placeholder="Select role"
                  keyValue="name"
                  valueKey="key"
                  label={"Role"}
                  onChange={(value) => setFieldValue("role", value)}
                />
              </div>
              <div className="w-full">
                {values?.comment && (
                  <CustomInputField
                    name={"comment"}
                    required={false}
                    readOnly={true}
                    placeholder=""
                    showOptionalText={false}
                    label={"Comment"}
                  />
                )}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="w-[20%]">
                <RoundedMdButton
                  label={"Close"}
                  disabled={isSubmitting}
                  onClick={onClose}
                />
              </div>
              <div className="w-[20%]">
                <RoundedMdButton
                  label={isEditing ? "Update" : "Submit"}
                  type="submit"
                  disabled={isSubmitting}
                  className="bg-[#FF9D21] font-[500] text-[16px] text-white"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {InfoDisplayComponent}

      <ConfirmationModal
        title={"Review User"}
        description={"You can activate or deactivate a user"}
        subText={
          "Activate a user if all details are correct. Otherwise, deactivate the user and provide some reason."
        }
        isOpen={openReviewModal}
        onClose={() => {
          setOpenReviewModal(false);
        }}
      >
        {showReasonInput && (
          <>
            <Formik
              initialValues={{
                comment: "",
              }}
              validate={(values) => {
                const errors = {};
                setComment(values.comment);
                if (!values.comment) {
                  errors.comment = "Please enter reason(s) for rejection";
                }
                return errors;
              }}
            >
              <Form className="mt-6">
                <CustomTextField
                  name={"comment"}
                  label={"Deactivation reason"}
                  placeholder="Please enter reason for deactivating"
                  required
                />
              </Form>
            </Formik>
          </>
        )}
        {!showReasonInput && (
          <div className="my-5 flex justify-between gap-6">
            <RoundedMdButton
              label={"Deactivate"}
              onClick={() => {
                setShowReasonInput(true);
              }}
              className="bg-gray-200"
            />
            <RoundedMdButton
              label={"Activate"}
              onClick={() => {
                handleReview(true, { id: selectedUser?.id });
              }}
              className="bg-orange-400 text-white"
            />
          </div>
        )}
        {showReasonInput && (
          <div className="my-5 flex justify-between gap-6">
            <RoundedMdButton
              label={"Cancel"}
              onClick={() => {
                setShowReasonInput(false);
              }}
              className="bg-gray-200"
            />
            <RoundedMdButton
              label={"Deactivate"}
              onClick={() => {
                handleReview(false, { id: selectedUser?.id, comment });
              }}
              className="bg-orange-400 text-white"
            />
          </div>
        )}
      </ConfirmationModal>
    </div>
  );
}

export default UserDetails;
