import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "../../commons/CustomDatePicker";
import CustomNavTabs from "../../commons/CustomNavTabs";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import PageTitle from "../../commons/PageTitle";
import { GENERAL_GRAY_HEX } from "../../constants/texts";
import SearchInput from "../../commons/SearchInput";
import { tickets_mock_data } from "../../mock_data/tickets";
import GeneralModal from "../../commons/GeneralModal";
import MakerContactDetails from "./SupportTicketDetails";
import TicketsListing from "./TicketsListing";
import useSupportTickets from "../../hooks/useSupportTickets";
import useUser from "../../hooks/useUser";
import useDebounce from "../../hooks/useDebounce";
import useDatePicker from "../../hooks/useDatePicker";

const tabItems = ["All", "Open", "Closed"];
const dateFormat = "YYYY-MM-DD";

function SupportTickets() {
  const navigate = useNavigate();
  const [selectedMaker, setSelectedMaker] = useState(null);
  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);
  const [searchTerm, setSearchTerm] = useState("");

  const { user } = useUser();

  const debouncedSearchTerm = useDebounce(searchTerm);

  const { supportTickets, isLoading } = useSupportTickets({
    accessToken: user?.token,
    status: getStatus(selectedTab),
    searchTerm: debouncedSearchTerm,
    dateRange: selectedDate,
  });

  const { DatePickerComponent, startDate, endDate, DateDisplay } =
    useDatePicker(() => {}, dateFormat);

  function getStatus(status) {
    if (status == tabItems[1]) {
      return 1;
    } else if (status == tabItems[2]) {
      return 0;
    }
    return "";
  }

  const handleRowClick = (id) => {
    navigate(`/support-tickets/${id}`);
  };

  useEffect(() => {
    setSelectedDate([startDate, endDate]);
  }, [startDate, endDate]);

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left p-6">
        <PageTitle title={"Support Tickets"} />
        <PageBreadCrumb
          mainText="Dashboard"
          subText="Support Tickets List"
          otherText=""
          isActiveIndex={1}
        />
        <div className={`flex justify-between mt-6 text-[${GENERAL_GRAY_HEX}]`}>
          <div>
            <CustomNavTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabItems}
            />
          </div>
          <div className={`flex gap-4`}>
            <SearchInput
              className="bg-white shadow-sm py-3"
              onChange={setSearchTerm}
              value={searchTerm}
              placeholder={"Search tickets..."}
            />
            {DatePickerComponent}
          </div>
        </div>

        {DateDisplay}

        <TicketsListing
          onRowClick={handleRowClick}
          isLoading={isLoading}
          tickets={supportTickets}
          totalRecords={supportTickets?.totalRecords ?? 10}
        />
      </div>

      {/* MOdal */}
      <GeneralModal
        widthClass="w-1/2"
        showCloseButton
        isOpen={selectedMaker}
        onClose={() => setSelectedMaker(null)}
      >
        <div className="h-[80vh] text-left pr-3" style={{ overflowY: "auto" }}>
          <MakerContactDetails onClose={() => setSelectedMaker(null)} />
        </div>
      </GeneralModal>
    </Layout>
  );
}

export default SupportTickets;
