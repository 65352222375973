import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomNavTabs from "../../commons/CustomNavTabs";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import PageTitle from "../../commons/PageTitle";
import { GENERAL_GRAY_HEX } from "../../constants/texts";
import GeneralModal from "../../commons/GeneralModal";
import UserDetails from "./UserDetails";
import CustomButton from "../../commons/CustomButton";
import PlusIcon from "../../commons/PlusIcon";
import UsersListing from "./UsersListing";
import useUser from "../../hooks/useUser";
import useUsers from "../../hooks/useUsers";
import useAddAdminUser from "../../hooks/useAddAdminUser";
import useCustomInfoDisplay from "../../hooks/useCustomInfoDisplay";
import CustomBackdrop from "../../commons/CustomBackdrop";
import useUpdateAdminUser from "../../hooks/useUpdateAdminUser";
import SearchInput from "../../commons/SearchInput";
import useDebounce from "../../hooks/useDebounce";
import useReviewAdminUser from "../../hooks/useReviewAdminUser";

const tabItems = ["All", "Active", "Inactive"];

function Users() {
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { user } = useUser();

  const debouncedSearchTerm = useDebounce(searchTerm);

  const { InfoDisplayComponent, setInfoDetails } = useCustomInfoDisplay();

  const { users, isLoading } = useUsers(
    { accessToken: user?.token },
    pageSize,
    pageNumber,
    selectedTab?.toLowerCase(),
    debouncedSearchTerm
  );

  const { addAdminUser } = useAddAdminUser();
  const { adminUserReview } = useReviewAdminUser();
  const { updateAdminUser } = useUpdateAdminUser();

  const handleRowClick = (id) => {
    setIsEditing(true);
    setSelectedUser(users?.find((user) => user.guid == id));
  };

  const handleUserDetailsSubmission = (
    values,
    callback,
    finallyCallback,
    isReview
  ) => {
    let action = addAdminUser;
    let text = "created";

    if (isReview) {
      action = adminUserReview;
      text = values.comment ? "deactivated" : "activated";
    } else if (isEditing) {
      action = updateAdminUser;
      text = "updated";
    }

    setIsSubmitting(true);
    action({ ...values, accessToken: user?.token })
      .then((res) => {
        setSelectedUser(null);
        setInfoDetails({
          message: `Admin ${text} successfully`,
          isError: false,
        });
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        setInfoDetails({ message: err?.message, isError: true });
      })
      .finally(() => {
        if (finallyCallback) {
          finallyCallback();
        }
        setIsSubmitting(false);
      });
  };

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left p-6">
        <PageTitle title={"User Management"} />
        <PageBreadCrumb
          mainText="Dashboard"
          subText="User Management"
          otherText=""
          isActiveIndex={1}
        />
        <div className={`flex justify-between mt-6 text-[${GENERAL_GRAY_HEX}]`}>
          <div>
            <CustomNavTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabItems}
            />
          </div>
          <div className={`flex gap-4`}>
            <SearchInput
              className="bg-white shadow-sm py-3"
              onChange={setSearchTerm}
              placeholder={"Search users..."}
              value={searchTerm}
            />
            <CustomButton
              onClick={() => {
                setSelectedUser({});
                setIsEditing(false);
              }}
              label="User"
              className="bg-orange-400 text-white"
            >
              <PlusIcon />
            </CustomButton>
          </div>
        </div>

        <UsersListing
          users={users}
          isLoading={isLoading}
          totalRecords={users?.totalRecords}
          onRowClick={handleRowClick}
        />
      </div>

      {/* Modal */}
      <GeneralModal
        widthClass="w-1/2"
        showCloseButton
        isOpen={selectedUser}
        onClose={() => setSelectedUser(null)}
      >
        <div className="h-[80vh] text-left pr-3" style={{ overflowY: "auto" }}>
          <UserDetails
            isEditing={isEditing}
            selectedUser={selectedUser}
            onSubmit={handleUserDetailsSubmission}
            onClose={() => setSelectedUser(null)}
            callback={() => setSelectedUser(null)}
          />
        </div>
      </GeneralModal>

      {InfoDisplayComponent}

      {isSubmitting && (
        <CustomBackdrop open={isSubmitting} text="Please wait..." />
      )}
    </Layout>
  );
}

export default Users;
