import useEditCustomer from "../../hooks/useEditCustomer";
import useUser from "../../hooks/useUser";
import { useState } from "react";
import useCustomer from "../../hooks/useCustomer";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import CustomInputField from "../../commons/CustomInputField";
import FullRoundedButton from "../../commons/FullRoundedButton";
import CustomThreeDotsSpinner from "../../commons/spinners/CustomThreeDotsSpinner";
import CustomSnackBar from "../../commons/CustomSnackBar";
import Layout from "../../commons/Layout";
import PageTitle from "../../commons/PageTitle";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import CustomButton from "../../commons/CustomButton";
import { GENERAL_GRAY_HEX } from "../../constants/texts";
import CardTitle from "../admin_commons/CardTitle";

function CustomerEditProfile() {
  const params = useParams();
  const navigate = useNavigate();

  const id = params.slug;
  const { updateUser } = useEditCustomer(id);
  const { user } = useUser();
  const { customer } = useCustomer({ id });

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left py-6 px-3">
        <PageTitle title={`${customer?.firstName}'s Profile`} />
        <div className="flex justify-between mb-4">
          <PageBreadCrumb
            mainText="Customers"
            subText="Customers List"
            otherText="Edit Customer Details"
            isActiveIndex={2}
          />

          <div className="flex gap-4">
            <CustomButton
              label="Back"
              onClick={() => navigate(-1)}
              className={`border border-[${GENERAL_GRAY_HEX}] text-[${GENERAL_GRAY_HEX}]`}
            />
          </div>
        </div>
        <div className="p-4 shadow-sm rounded-md w-full bg-white">
          <Formik
            enableReinitialize
            initialValues={{
              firstName: customer?.firstName,
              lastName: customer?.lastName,
              email: customer?.email,
              phoneNumber: customer?.phoneNumber,
              guid: customer?.guid,
              accessToken: user?.token,
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              updateUser(values)
                .then((res) => {
                  setTimeout(() => {
                    navigate(`/customers/${id}`);
                  }, 1000);
                  setSnackBarMessage("Customer successfully updated");
                  setIsError(false);
                  setOpenSnackBar(true);
                })
                .catch((err) => {
                  setSnackBarMessage(err?.message);
                  setIsError(true);
                  setOpenSnackBar(true);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Email is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              if (!values.lastName) {
                errors.lastName = "Last name is required";
              }
              if (!values.firstName) {
                errors.firstName = "First name is required";
              }
              if (!values.phoneNumber) {
                errors.phoneNumber = "Mobile number is required";
              }
              return errors;
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="flex max-md:flex-col items-center gap-4 max-md:gap-0 justify-between">
                  <div className="w-full">
                    <CustomInputField
                      label={"First Name"}
                      type="text"
                      placeholder="Please enter your first name"
                      name="firstName"
                      disabled={isSubmitting}
                    />
                  </div>
                  <div className="w-full">
                    <CustomInputField
                      label={"Last Name"}
                      type="text"
                      placeholder="Please enter your last name"
                      name="lastName"
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
                <CustomInputField
                  label={"Email Address"}
                  type="email"
                  placeholder="Please enter your email address"
                  name="email"
                  disabled={isSubmitting}
                />
                <CustomInputField
                  label={"Phone Number"}
                  type="text"
                  placeholder="Please enter your phone number"
                  name="phoneNumber"
                  disabled={isSubmitting}
                />
                <div className="w-[30%] max-md:w-1/2 mt-4">
                  <FullRoundedButton
                    disabled={isSubmitting}
                    label={isSubmitting ? "Please wait" : "Update profile"}
                    className="text-[14px] max-md:text-[12px] text-white bg-[#FF9D21]"
                    type="submit"
                  >
                    {isSubmitting && (
                      <CustomThreeDotsSpinner
                        height="20"
                        width="20"
                        color="white"
                      />
                    )}
                  </FullRoundedButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <CustomSnackBar
          isOpen={openSnackBar}
          onClose={() => setOpenSnackBar(false)}
          message={snackBarMessage}
          isError={isError}
        />
      </div>
    </Layout>
  );
}

export default CustomerEditProfile;
