import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { CUSTOMERS_QUERY_KEY } from "../constants/queryKeys";
import { decryptObject } from "../helpers/encryptor";
import { getCustomerList } from "../services/customers-service";

function useCustomers(
  data,
  pageSize,
  pageNumber,
  filter,
  searchTerm,
  dateRange
) {
  const navigate = useNavigate();
  const {
    data: customers,
    isLoading,
    error,
  } = useQuery({
    queryKey: [
      CUSTOMERS_QUERY_KEY,
      pageSize,
      pageNumber,
      filter,
      searchTerm,
      dateRange,
    ],
    queryFn: async () => {
      const result = await getCustomerList(
        data,
        pageSize,
        pageNumber,
        filter,
        searchTerm,
        dateRange
      );
      return result?.data?.data;
    },
    onError: (error) => {
      // return error
    },
  });

  return {
    customerItems: {
      totalRecords: customers?.totalRecords,
      customers: decryptObject(customers?.users),
    },
    isLoading,
    error,
  };
}

export default useCustomers;
