import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { VENDOR_PAYOUTS_QUERY_KEY } from "../constants/queryKeys";
import { decryptData, decryptUser } from "../helpers/encryptor";
import { getVendorPayouts } from "../services/payouts-services";

function useVendorPayouts(user, pageSize, pageNumber) {
  const navigate = useNavigate();
  const {
    data: vendorPayouts,
    isLoading,
    error,
  } = useQuery({
    queryKey: [VENDOR_PAYOUTS_QUERY_KEY, user?.vendorId, pageSize, pageNumber],
    queryFn: async () => {
      const result = await getVendorPayouts(user, pageSize, pageNumber);
      return result?.data;
    },
    onError: (error) => {
      // return error
    },
    enabled: user.vendorId != null,
  });
  return {
    vendorPayouts,
    isLoading,
    error,
  };
}

export default useVendorPayouts;
