import React, { useReducer, useState, useEffect } from "react";
import Layout from "../../../commons/Layout";
import RadioItem from "../../../commons/RadioItem";
import {
  ACTIVE,
  MAX_FULL_DESCRIPTION,
  MAX_SHORT_DESCRIPTION,
  MIN_FULL_DESCRIPTION,
  MIN_SHORT_DESCRIPTION,
  PENDING,
  REJECTED,
} from "../../../constants/texts";
import { radioGroupHandler } from "../../../reducers/radioGroupHandler";
import { toggleRadioSelection } from "../../../reducers/radioGroupHandler/actions";
import HeadingAndDetails from "../../vendor_onboarding/HeadingAndDetails";
import PageTitle from "../../../commons/PageTitle";
import { Formik, Form } from "formik";
import CustomInputField from "../../../commons/CustomInputField";
import CustomTextField from "./../../../commons/CustomTextField";
import CheckboxItem from "../../../commons/CheckboxItem";
import RoundedMdButton from "../../../commons/RoundedMdButton";
import { listCreationReducer } from "../../../reducers/listCreationReducer";
import { addItemToList } from "../../../reducers/listCreationReducer/actions";
import ProductImageUploaderUI from "./ProductImageUploaderUI";
import { useNavigate, useParams } from "react-router-dom";
import useProductCategories from "../../../hooks/useProductCategories";
import useColors from "./../../../hooks/useColors";
import {
  fetchSubcategoriesFromProductCategoryById,
  fetchTypesFromProductSubcategoryById,
} from "../../../helpers/service-product-helpers";
import useAddVendorProduct from "../../../hooks/useAddVendorProduct";
import useUser from "../../../hooks/useUser";
import CustomSnackBar from "../../../commons/CustomSnackBar";
import useProduct from "./../../../hooks/useProduct";
import useCustomInfoDisplay from "./../../../hooks/useCustomInfoDisplay";
import PageBreadCrumb from "../../../commons/PageBreadCrumb";
import { GENERAL_GRAY_HEX } from "../../../constants/texts";
import CustomButton from "../../../commons/CustomButton";
import CustomSelectField from "./../../../commons/CustomSelectField";
import useVendors from "../../../hooks/useVendors";
import CustomBackdrop from "../../../commons/CustomBackdrop";
import { APPROVED } from "./../../../constants/texts";
import usePublishProduct from "./../../../hooks/usePublishProduct";
import { delayAction } from "../../../helpers/events";

function AddProduct({ editing, adding, readOnly = false }) {
  // inits
  const initialState = {
    radioValue: "",
    list: [],
  };

  const checkboxInitialState = {
    list: [],
  };

  // hooks
  const navigate = useNavigate();

  const { messageState, setInfoDetails, InfoDisplayComponent } =
    useCustomInfoDisplay();
  const { user } = useUser();
  const params = useParams();
  const { slug } = params;
  const { product, isLoading: productFetchLoading } = useProduct(user, slug);
  const { productCategories } = useProductCategories();
  const { addVendorProduct } = useAddVendorProduct();
  const { publishVendorProduct } = usePublishProduct();

  const { vendors } = useVendors({
    accessToken: user?.token,
    pageSize: 100,
    pageNumber: 1,
    searchTerm: "",
    status: ACTIVE?.toLowerCase(),
    dateRange: ["", ""],
  });

  const { colors } = useColors();

  const [status, setStatus] = useState("");

  const [state, dispatch] = useReducer(radioGroupHandler, initialState);
  const [checkboxState, checkboxDispatch] = useReducer(
    listCreationReducer,
    checkboxInitialState
  );

  const [listState, listDispatch] = useReducer(
    listCreationReducer,
    initialState
  );

  // State variables
  const [subcategories, setSubcategories] = useState([]);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isError, setIsError] = useState(false);
  const [submittingChanges, setSubmittingChanges] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [action, setAction] = useState(null);

  // custom functions
  function getHeaderText() {
    if (readOnly) {
      return "View Product";
    } else if (editing) {
      return "Edit Product";
    }

    return "Add Product";
  }

  function handleRadioChange(value) {
    dispatch(toggleRadioSelection(value));
  }

  function addToCheckedItems(value) {
    listDispatch(addItemToList(value));
  }

  function addToColorItems(value) {
    checkboxDispatch(addItemToList(value));
  }

  function handlePublishProduct(data) {
    setSubmittingChanges(true);
    publishVendorProduct(data)
      .then((res) => {
        setInfoDetails({ message: res?.data?.message, isError: false });
        delayAction(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((err) => {
        setInfoDetails({ message: err?.message, isError: true });
      })
      .finally(() => {
        setSubmittingChanges(false);
      });
  }

  const resetOtherInputs = () => {};

  //Effects

  useEffect(() => {
    if (!selectedProduct) {
      setSelectedProduct(product);
    }
  }, [product]);

  //Effect to update all reducer values if product changes or exists
  useEffect(() => {
    handleRadioChange(selectedProduct?.Type?.Subcategory?.Category?.id ?? "");
  }, [selectedProduct?.Type?.Subcategory?.Category?.id]);

  useEffect(() => {
    const needsInstallation = selectedProduct?.needsInstallation;
    if (needsInstallation) {
      addToCheckedItems("installation");
    }
  }, [selectedProduct?.needsInstallation]);

  useEffect(() => {
    const colors = selectedProduct?.colors;
    if (colors) {
      const colorsArray = colors.split(",");
      for (const c of colorsArray) {
        addToColorItems(c);
      }
    }
  }, [selectedProduct?.colors]);

  useEffect(() => {
    const subs = fetchSubcategoriesFromProductCategoryById(
      productCategories,
      state.radioValue
    );
    setSubcategories(subs);
  }, [state.radioValue]);

  useEffect(() => {
    setStatus(selectedProduct?.status);
  }, [selectedProduct]);

  return (
    <Layout bgClassName="bg-gray-100">
      {((!productFetchLoading && selectedProduct) || adding) && (
        <div className="py-6 px-4 mx-auto text-left">
          {((editing && selectedProduct) || !editing) && (
            <Formik
              // enableReinitialize={selectedProduct != null}
              initialValues={{
                id: selectedProduct?.id,
                containsFile: true,
                productCategory: state.radioValue,
                name: selectedProduct?.name ?? "",
                typeId: selectedProduct?.TypeId,
                productSubcategory: selectedProduct?.Type?.Subcategory?.id,
                shortDescription: selectedProduct?.shortDescription ?? "",
                fullDescription: selectedProduct?.fullDescription ?? "",
                width: selectedProduct?.width ?? 0,
                height: selectedProduct?.height ?? 0,
                weight: selectedProduct?.weight ?? 0,
                length: selectedProduct?.length ?? 0,
                installationPrice: selectedProduct?.installationPrice ?? 0,
                quantity: selectedProduct?.quantity ?? 0,
                imageOne: selectedProduct?.imageOne ?? "",
                imageTwo: selectedProduct?.imageTwo ?? "",
                imageThree: selectedProduct?.imageThree ?? "",
                imageFour: selectedProduct?.imageFour ?? "",
                imageFive: selectedProduct?.imageFive ?? "",
                imageSix: selectedProduct?.imageSix ?? "",
                price: selectedProduct?.price ?? 0,
                discountPrice: selectedProduct?.discountPrice ?? 0,
                needsInstallation: false,
                colors: checkboxState.list,
                vendorId: selectedProduct?.Vendor?.id,
                status: selectedProduct?.status,
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmittingChanges(true);
                addVendorProduct({
                  ...values,
                  status,
                  needsInstallation: listState.list.length > 0,
                  accessToken: user?.token,
                  colors: checkboxState.list,
                })
                  .then((res) => {
                    setInfoDetails({
                      message: res?.data?.message,
                      isError: false,
                    });

                    setTimeout(() => {
                      setSubmitting(false);
                      navigate("/products", {
                        state: { message: res.data.message, isError: false },
                      });
                    }, 2000);

                    resetForm();
                    resetOtherInputs();
                  })
                  .catch((err) => {
                    console.log("err", err);
                    setInfoDetails({ message: err?.message, isError: true });
                    setSubmitting(false);
                  })
                  .finally(() => {
                    setSubmittingChanges(false);
                  });
              }}
              validate={(values) => {
                const errors = {};
                if (readOnly) {
                  return;
                }
                if (!values.name) {
                  errors.name = "Product name is required";
                }
                // if (!values.productCategory) {
                //   errors.productCategory = "Product category is required";
                // }
                if (!values.typeId) {
                  errors.typeId = "Product type is required";
                }
                if (!values.quantity) {
                  errors.quantity = "Product quantity is required";
                }
                if (!values.price) {
                  errors.price = "Product price is required";
                }
                if (!values.width) {
                  errors.width = "Width is required";
                }
                if (!values.length) {
                  errors.length = "Length is required";
                }
                if (!values.height) {
                  errors.height = "Height is required";
                }
                if (!values.weight) {
                  errors.weight = "Weight is required";
                }
                if (!values.shortDescription) {
                  errors.shortDescription = "Short description is required";
                }
                if (values.shortDescription.length > MAX_SHORT_DESCRIPTION) {
                  errors.shortDescription = `Short description should not be more than ${MAX_SHORT_DESCRIPTION} characters`;
                }
                // if (!values.fullDescription) {
                //   errors.fullDescription = "Full description is required";
                // }
                // if (values.fullDescription.length > MAX_FULL_DESCRIPTION) {
                //   errors.fullDescription = `Full description should not be more than ${MAX_FULL_DESCRIPTION} characters`;
                // }
                if (!values.imageOne) {
                  errors.imageOne = "Image 1 is required";
                }

                if (listState.list.includes("installation")) {
                  if (!values.installationPrice) {
                    errors.installationPrice =
                      "Installation price is required.";
                  }
                }

                return errors;
              }}
            >
              {({
                isSubmitting,
                setFieldValue,
                setFieldError,
                values,
                setErrors,
                errors,
              }) => (
                <Form>
                  <PageTitle title={"Products"} />
                  <div className="flex justify-between mb-8">
                    <PageBreadCrumb
                      mainText="Dashboard"
                      subText="Product List"
                      otherText={getHeaderText()}
                      isActiveIndex={2}
                    />

                    <div className="flex gap-4">
                      <CustomButton
                        label="Cancel"
                        onClick={() => navigate(-1)}
                        className={`border border-[${GENERAL_GRAY_HEX}] text-[${GENERAL_GRAY_HEX}]`}
                      />
                      {(editing || adding) && (
                        <CustomButton
                          label={
                            isSubmitting ? "Please wait..." : "Save changes"
                          }
                          type={"submit"}
                          disabled={isSubmitting}
                          className={`bg-orange-400 border-[${GENERAL_GRAY_HEX}] text-[white]`}
                        />
                      )}
                      {readOnly && (
                        <CustomButton
                          label="Edit"
                          onClick={() =>
                            navigate("/products/edit/" + selectedProduct?.slug)
                          }
                          className={`bg-orange-400 border-[${GENERAL_GRAY_HEX}] text-[white]`}
                        />
                      )}
                      {readOnly && (
                        <CustomButton
                          label="Publish"
                          onClick={() =>
                            handlePublishProduct({
                              id: selectedProduct?.id,
                              accessToken: user?.token,
                            })
                          }
                          className={`bg-orange-400 border-[${GENERAL_GRAY_HEX}] text-[white]`}
                        />
                      )}
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="w-[70%]">
                      {/* Product Section */}
                      <div className="shadow-sm bg-white rounded-md p-5">
                        <HeadingAndDetails
                          title={"Product Section"}
                          description={
                            "Select which section you want to feature the product"
                          }
                        />
                        <div className="mt-5 flex gap-4">
                          {productCategories?.map((category) => {
                            return (
                              <RadioItem
                                value={category?.id}
                                disabled={readOnly || isSubmitting}
                                readOnly={readOnly}
                                selectedValue={state.radioValue}
                                onClick={() => handleRadioChange(category?.id)}
                                label={category?.name}
                              />
                            );
                          })}
                        </div>
                      </div>

                      {/* Product Details */}
                      <div className="mt-6 shadow-sm bg-white rounded-md p-5">
                        <HeadingAndDetails
                          title={"Product Details"}
                          description={"Fill in the product details below"}
                        />
                        <div className="mt-2"></div>

                        <div className="flex items-start gap-4">
                          <div className="w-full">
                            <CustomInputField
                              readOnly={readOnly}
                              name={"name"}
                              required={true}
                              placeholder="Enter product name"
                              label={"Product Name"}
                            />
                          </div>
                          <div className="w-full">
                            <CustomSelectField
                              readOnly={readOnly}
                              name={"productSubcategory"}
                              required={true}
                              placeholder="Select product subcategory"
                              label={"Product Subcategory"}
                              list={subcategories}
                              selectedValue={
                                selectedProduct?.Type?.Subcategory?.id
                              }
                              valueKey={"id"}
                              keyValue={"name"}
                              onChange={(value) =>
                                setFieldValue("productSubcategory", value)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex items-start gap-4">
                          <div className="w-full">
                            <CustomSelectField
                              readOnly={readOnly}
                              name={"typeId"}
                              required={true}
                              placeholder="Select product type"
                              label={"Product Type"}
                              valueKey={"id"}
                              keyValue={"name"}
                              selectedValue={selectedProduct?.Type?.id}
                              list={
                                fetchTypesFromProductSubcategoryById(
                                  subcategories,
                                  values.productSubcategory
                                ) ?? []
                              }
                              onChange={(value) =>
                                setFieldValue("typeId", value)
                              }
                            />
                          </div>
                          <div className="w-full">
                            <CustomInputField
                              readOnly={readOnly}
                              name={"price"}
                              required={true}
                              type="number"
                              placeholder="Enter product price"
                              label={"Product Price"}
                            />
                          </div>
                        </div>
                        <div className="flex items-start gap-4">
                          <div className="w-full">
                            <CustomInputField
                              readOnly={readOnly}
                              name={"discountPrice"}
                              required={false}
                              type="number"
                              placeholder="Enter product discount price (Ex. 10 for 10%)"
                              label={"Product Discount Price (in %)"}
                            />
                          </div>
                          <div className="w-full">
                            <CustomInputField
                              readOnly={readOnly}
                              name={"quantity"}
                              required={true}
                              type="number"
                              placeholder="Enter quantity available"
                              label={"Quantity Available"}
                            />
                          </div>
                        </div>
                        <div className="flex items-start gap-4">
                          <div className="w-full">
                            <CustomInputField
                              readOnly={readOnly}
                              name={"weight"}
                              required={true}
                              placeholder="Enter weight in KG"
                              label={"Weight (KG)"}
                              type="number"
                            />
                          </div>
                          <div className="w-full">
                            <CustomInputField
                              readOnly={readOnly}
                              name={"length"}
                              required={true}
                              placeholder="Enter length in CM"
                              label={"Length (CM)"}
                              type="number"
                            />
                          </div>
                        </div>
                        <div className="flex items-start gap-4">
                          <div className="w-full">
                            <CustomInputField
                              readOnly={readOnly}
                              name={"height"}
                              required={true}
                              placeholder="Enter height in CM"
                              label={"Height (CM)"}
                              type="number"
                            />
                          </div>
                          <div className="w-full">
                            <CustomInputField
                              readOnly={readOnly}
                              name={"width"}
                              required={true}
                              placeholder="Enter width in CM"
                              label={"Width (CM)"}
                              type="number"
                            />
                          </div>
                        </div>
                      </div>

                      {/* General information */}
                      <div className="shadow-sm bg-white rounded-md p-5 mt-6">
                        <HeadingAndDetails
                          title={"General Information"}
                          description={""}
                        />
                        <div className="flex items-center gap-4 ">
                          <div className="w-full">
                            <CustomTextField
                              name={"shortDescription"}
                              extraText={`Max. ${MAX_SHORT_DESCRIPTION} characters`}
                              required={true}
                              placeholder="Enter short description"
                              label={"Short Description"}
                              showProgress={true}
                              value={values.shortDescription}
                              max={MAX_SHORT_DESCRIPTION}
                              min={MIN_SHORT_DESCRIPTION}
                              readOnly={readOnly}
                            />
                          </div>
                        </div>
                        <div className="flex items-center gap-4">
                          <div className="w-full">
                            <CustomTextField
                              name={"fullDescription"}
                              // extraText={`Max. ${MAX_FULL_DESCRIPTION} characters`}
                              required={false}
                              placeholder="Enter full description"
                              label={"Full Description"}
                              value={values.fullDescription}
                              // max={MAX_FULL_DESCRIPTION}
                              // min={MIN_FULL_DESCRIPTION}
                              showProgress={true}
                              readOnly={readOnly}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Product Images */}
                      <div className="mt-1 shadow-sm rounded-md p-4 bg-white mt-6">
                        <HeadingAndDetails
                          title={"Product Images"}
                          description={
                            "Upload different image angles of the product"
                          }
                        />

                        <div className="flex gap-4 mb-1">
                          <ProductImageUploaderUI
                            label={"Image 1"}
                            required
                            name={"imageOne"}
                            setFieldError={setFieldError}
                            onChange={(value) =>
                              setFieldValue("imageOne", value)
                            }
                            setErrors={setErrors}
                            errors={errors}
                            file={values.imageOne}
                            disabled={isSubmitting || readOnly}
                          />
                          <ProductImageUploaderUI
                            label={"Image 2"}
                            name={"imageTwo"}
                            setFieldError={setFieldError}
                            onChange={(value) =>
                              setFieldValue("imageTwo", value)
                            }
                            setErrors={setErrors}
                            errors={errors}
                            file={values.imageTwo}
                            disabled={isSubmitting || readOnly}
                          />
                        </div>
                        <div className="flex gap-4">
                          <ProductImageUploaderUI
                            label={"Image 3"}
                            name={"imageThree"}
                            setFieldError={setFieldError}
                            setErrors={setErrors}
                            errors={errors}
                            onChange={(value) =>
                              setFieldValue("imageThree", value)
                            }
                            file={values.imageThree}
                            disabled={isSubmitting || readOnly}
                          />
                          <ProductImageUploaderUI
                            label={"Image 4"}
                            name={"imageFour"}
                            setFieldError={setFieldError}
                            setErrors={setErrors}
                            errors={errors}
                            onChange={(value) =>
                              setFieldValue("imageFour", value)
                            }
                            file={values.imageFour}
                            disabled={isSubmitting || readOnly}
                          />
                        </div>
                        <div className="flex gap-4">
                          <ProductImageUploaderUI
                            label={"Image 5"}
                            name={"imageFive"}
                            setFieldError={setFieldError}
                            setErrors={setErrors}
                            errors={errors}
                            onChange={(value) =>
                              setFieldValue("imageFive", value)
                            }
                            file={values.imageFive}
                            disabled={isSubmitting || readOnly}
                          />
                          <ProductImageUploaderUI
                            label={"Image 6"}
                            name={"imageSix"}
                            setFieldError={setFieldError}
                            setErrors={setErrors}
                            errors={errors}
                            onChange={(value) =>
                              setFieldValue("imageSix", value)
                            }
                            file={values.imageSix}
                            disabled={isSubmitting || readOnly}
                          />
                        </div>
                      </div>

                      {/* Product Colors */}
                      <div className="mt-6 p-4 shadow-sm bg-white rounded-md">
                        <HeadingAndDetails
                          title={"Colors Available"}
                          description={
                            "Select the colors the product is available in"
                          }
                        />
                        <div className="grid grid-cols-5 justify-start mt-2 gap-2 cursor-pointer">
                          {colors?.map((color, index) => {
                            return (
                              <>
                                <div className="flex gap-2 items-center">
                                  <input
                                    type="checkbox"
                                    className="w-[18px] h-[18px]"
                                    id={index}
                                    value={color?.name}
                                    checked={checkboxState.list.includes(
                                      color?.name
                                    )}
                                    onChange={() =>
                                      addToColorItems(color?.name)
                                    }
                                    disabled={readOnly}
                                    readOnly={readOnly}
                                  />
                                  <label
                                    for={index}
                                    className="font-[500] text-[14px]"
                                  >
                                    {color?.name}
                                  </label>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="w-[28%]">
                      <div className="rounded-md shadow-sm bg-white p-5">
                        <HeadingAndDetails title={"Status"} description={""} />
                        <div className="mt-2"></div>
                        <CustomSelectField
                          selectedValue={status}
                          placeholder="Select status"
                          readOnly={readOnly}
                          disabled={isSubmitting || readOnly}
                          onChange={setStatus}
                          label={"Product Status"}
                          name="status"
                          list={[PENDING, APPROVED, REJECTED]}
                        />
                      </div>

                      {/* Vendor details */}
                      <div className="mt-6 shadow-sm bg-white rounded-md p-5">
                        <HeadingAndDetails
                          title={"Assign Vendor"}
                          description={
                            "Select the vendor that this product will be assigned to."
                          }
                        />

                        <div className="flex gap-4 mt-2 items-center">
                          <div className="w-full">
                            <CustomSelectField
                              readOnly={readOnly}
                              label="Vendor"
                              type="number"
                              required={true}
                              name="vendor"
                              placeholder="Choose vendor"
                              list={vendors?.vendors ?? []}
                              valueKey="id"
                              keyValue="businessName"
                              selectedValue={values.vendorId}
                              onChange={(value) =>
                                setFieldValue("vendorId", value)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      {/* Installation */}
                      <div className="my-6 shadow-sm bg-white rounded-md p-5">
                        <HeadingAndDetails
                          title={"Installation"}
                          description={
                            "Specify if this product requires professional installation."
                          }
                        />

                        <div className="mt-2 items-center">
                          <div className="w-full mt-1.5">
                            <CheckboxItem
                              label={
                                <span className="text-[14px]">
                                  The product requires professional installation
                                </span>
                              }
                              selected={listState.list.includes("installation")}
                              value={"installation"}
                              groupName={""}
                              onClick={() => addToCheckedItems("installation")}
                              padding="p-[0.9em]"
                              boxLeft={true}
                              disabled={readOnly}
                            />
                          </div>
                          <div className="w-full mt-5">
                            {listState.list.includes("installation") && (
                              <CustomInputField
                                readOnly={readOnly}
                                label="Installation Price"
                                type="number"
                                name="installationPrice"
                                placeholder="Enter installation price"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="flex mt-5 mb-8">
                  <div className="w-[20%]">
                    <RoundedMdButton
                      label={isSubmitting ? "Please wait..." : "Submit"}
                      disabled={isSubmitting}
                      className="bg-[#FF9D21] font-[500] text-[16px] text-white"
                      type="submit"
                    />
                  </div>
                </div> */}
                </Form>
              )}
            </Formik>
          )}
        </div>
      )}

      {(productFetchLoading || submittingChanges) && (
        <CustomBackdrop open={true} text="Please wait" />
      )}

      <CustomSnackBar
        isOpen={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        message={snackBarMessage}
        isError={isError}
      />
      {InfoDisplayComponent}
    </Layout>
  );
}

export default AddProduct;
