import React from "react";
import { GENERAL_GRAY_HEX } from "../constants/texts";
import CustomPriceDisplay from "./CustomPriceDisplay";

function CustomTopInfoFilterCard({
  data = [1, 2, 3, 4],
  filters,
  onFilterChange,
}) {
  return (
    <div className="bg-white shadow-sm p-4 w-full text-left rounded-md">
      <select
        className="w-full outline-none font-[500] text-[14px]"
        onChange={(e) => onFilterChange(e.target.value)}
      >
        {filters.map((filter, index) => {
          return <option value={filter.value}>{filter?.label}</option>;
        })}
      </select>
      <hr className="mt-4" />

      {data.map((d) => {
        return (
          <div className="flex gap-4 items-center mt-3">
            <div className="h-[4em] w-[4em]">
              <img
                className="rounded-md"
                src={d?.imagePath}
                style={{ objectFit: "cover" }}
                alt={d?.name}
              />
            </div>
            <div className="font-[400] text-[14px] ">
              <div className={`text-[${GENERAL_GRAY_HEX}]`}>{d?.name}</div>
              <div>
                <CustomPriceDisplay price={d?.amount} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CustomTopInfoFilterCard;
