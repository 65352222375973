import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_QUERY_KEY } from "../constants/queryKeys";
import { editCustomer } from "../services/customers-service";

function useEditCustomer(id) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateUser,
    isLoading,
    error,
    data,
  } = useMutation({
    mutationFn: (updateData) => editCustomer(updateData),
    onSuccess: (data) => {
      queryClient.invalidateQueries([CUSTOMER_QUERY_KEY]);
    },
  });
  return {
    updateUser,
    isLoading,
    error,
    data,
  };
}

export default useEditCustomer;
