import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { exportOrders } from "../services/exports-services";

function useExportOrders() {
  const navigate = useNavigate();
  const {
    mutateAsync: orderDataExport,
    isLoading,
    error,
  } = useMutation({
    mutationFn: async (dData) => exportOrders(dData),
    onSuccess: (result) => {
      return result;
    },
    onError: (error) => {},
  });
  return {
    orderDataExport,
    isLoading,
    error,
  };
}

export default useExportOrders;
