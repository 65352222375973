import { useNavigate } from "react-router-dom";
import CustomButton from "../commons/CustomButton";
import Layout from "../commons/Layout";
import { GENERAL_GRAY_HEX } from "../constants/texts";
import { useEffect } from "react";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 2000);
  }, []);

  return (
    <Layout bgClassName="bg-gray-100" showSideBar={false}>
      <div className="flex justify-center items-center h-[calc(100vh-100px)] max-md:h-[calc(100vh-200px)]">
        <div className="flex flex-col items-center">
          <p className="font-semibold text-[24px] max-md:text-[18px]">
            Page Not Found
          </p>
          <p className="pt-2 pb-8 text-[18px] max-md:text-[14px]">
            Oops! The page you are looking for does not exist.
          </p>
          <CustomButton
            label="Go to HomePage"
            onClick={() => {
              navigate("/");
            }}
            className={`!w-full border bg-orange-400 border-[${GENERAL_GRAY_HEX}] text-[white] flex justify-center`}
          />
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
