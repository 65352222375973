import React from "react";
import CustomPriceDisplay from "../../commons/CustomPriceDisplay";
import { capitalizeFirstLetter } from "../../helpers/strings";
import StyledSpan from "../../commons/StyledSpan";
import { getDateTime } from "../../helpers/dateTime";
import ReviewStar from "../../commons/ReviewStar";
import { getFullName } from "./../../helpers/extractors";

function OrderRowItem({ item, user, onClick = () => {} }) {
  return (
    <>
      <tr
        class="hover:bg-gray-100 cursor-pointer text-[12px]"
        onClick={onClick}
      >
        {/* <td
          scope="row"
          class="px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
        >
          <input type="checkbox" />
        </td> */}
        <td class="px-6 py-4 whitespace-nowrap">#{item?.orderNumber}</td>
        <td class="px-4 py-4">
          <div className="flex items-center gap-3">
            <img
              src={item?.Product?.imageOne}
              alt=""
              className="w-[3em] h-[3em] rounded-md"
              style={{ objectFit: "cover" }}
            />
            <span className="whitespace-nowrap">{item?.Product?.name}</span>
          </div>
        </td>
        <td class="px-4 py-4 whitespace-nowrap">
          {getDateTime(item?.createdAt)}
        </td>
        {user && (
          <td class="px-4 py-4">
            {getFullName(user?.firstName, user?.lastName)}
          </td>
        )}
        {!user && (
          <td class="px-4 py-4 whitespace-nowrap">
            {getFullName(item?.User?.firstName, item?.User?.lastName)}
          </td>
        )}
        <td class="px-4 py-4">
          <CustomPriceDisplay price={item?.total} />
        </td>
        <td class="px-4 py-4">
          <ReviewStar small value={item?.Review?.rating ?? 0} />
        </td>
        <td class="px-6 py-4 gap-3">
          <StyledSpan text={capitalizeFirstLetter(item?.trackingStatus)} />
        </td>

        {/* <td class="px-6 py-4">
          <div className="flex gap-3">
            <div className="text-[#318EC9] cursor-pointer">View</div>
            <div className="text-[#008000] cursor-pointer">Edit</div>
          </div>
        </td> */}
      </tr>
    </>
  );
}

export default OrderRowItem;
