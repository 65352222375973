import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const exportOrders = async (data) => {
  let url = `/admin/export/orders`;

  return axiosInstance(
    `${url}?status=${data?.filter}&searchTerm=${data?.searchTerm}&dateFrom=${data?.dateRange[0]}&dateTo=${data?.dateRange[1]}`,
    "GET",
    data,
    data.token,
    "blob"
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};

export const exportReturnRequests = async (data) => {
  let url = `/admin/export/return-requests`;

  return axiosInstance(
    `${url}?status=${data?.filter}&searchTerm=${data?.searchTerm}&dateFrom=${data?.dateRange[0]}&dateTo=${data?.dateRange[1]}`,
    "GET",
    data,
    data.token,
    "blob"
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};
