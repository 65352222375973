import { addPaginationUrlQuery } from "../helpers/extractors";
import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const fetchProducts = async () => {
  return axiosInstance(`/products`, "GET", null, null)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const addProduct = async (data) => {
  return axiosInstance(`/products`, "POST", data, data?.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};
export const publishProduct = async (data) => {
  return axiosInstance(`/products/${data?.id}`, "POST", data, data?.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const searchProducts = async (searchTerm) => {
  return axiosInstance(`/products/search-term/${searchTerm}`, "GET", null, null)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const filterProducts = async (
  pageSize,
  pageNumber,
  categoryList,
  subcategoryList,
  typeList,
  brandList,
  colorList,
  priceList,
  sorter,
  searchTerm,
  dateRange
) => {
  let newUrl = addPaginationUrlQuery(`/products/filter`, pageSize, pageNumber);
  return axiosInstance(
    newUrl +
      `&categories=${categoryList}&subcategories=${subcategoryList}&types=${typeList}&vendors=${brandList}&colors=${colorList}&prices=${priceList}&sorter=${sorter}&searchTerm=${searchTerm}&dateRange=${dateRange}`,
    "GET",
    null,
    null
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};

export const fetchProductBySlug = async (data, slug) => {
  return axiosInstance(`/products/slug/${slug}`, "GET", null, data?.accessToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      // return err
      getNetworkErrorMessage(err);
    });
};
