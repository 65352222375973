import React, { useLayoutEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getIntendedRoute } from "../local_store/intendedRouteLocalStore";
import Dashboard from "../pages/admin_dashboard/Dashboard";
import AccountProfile from "../pages/admin_profile/AccountProfile";
import Orders from "../pages/admin_orders/Orders";
import OrderDetails from "../pages/admin_orders/OrderDetails";
import ReturnedOrders from "../pages/admin_returned_orders/ReturnedOrders";
import ReturnedOrderDetails from "../pages/admin_returned_orders/ReturnedOrderDetails";
import Payouts from "../pages/admin_payouts/Payouts";
import PayoutDetails from "../pages/admin_payouts/PayoutDetails";
import LogisticsPartners from "../pages/admin_logistics_partners/LogisticsPartners";
import SupportTickets from "../pages/admin_support_tickets/SupportTickets";
import SupportTicketDetails from "../pages/admin_support_tickets/SupportTicketDetails";
import Installers from "../pages/admin_installers/Installers";
import Makers from "../pages/admin_makers/Makers";
import MakerContactDetails from "../pages/admin_makers/MakerContactDetails";
import Users from "../pages/admin_users/Users";
import Customers from "../pages/admin_customers/Customers";
import CustomerDetails from "../pages/admin_customers/CustomerDetails";
import Vendors from "../pages/admin_vendor/Vendors";
import VendorDetails from "../pages/admin_vendor/VendorDetails";
import Products from "../pages/admin_products/Products";
import AddProduct from "../pages/admin_products/add_products";
import Login from "../pages/auth/Login";
import Logout from "../pages/auth/Logout";
import ResetPassword from "../pages/auth/ResetPassword";
import LogisticsPartnerDetails from "../pages/admin_logistics_partners/LogisticsPartnersDetails";
import { isAdmin, isCSR, isSuperAdmin } from "../constants/roles";
import { getLoggedinUser } from "../helpers/events";
import CustomerEditProfile from "../pages/admin_customers/CustomerEditDetails";
import NotFound from "../pages/NotFound";

function useRouting() {
  const user = getLoggedinUser();

  const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    if (!user) {
      return <Navigate to="/auth/login" replace />;
    }

    return <>{children}</>;
  };

  const AuthenticatedRoute = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    const refreshedUser = location?.state?.user;

    if (!user || refreshedUser === -1) {
      return <>{children}</>;
    }

    const intendedRoute = getIntendedRoute("/dashboard");

    return <Navigate to={intendedRoute} replace />;
  };

  const routeList = [
    //1
    {
      path: "/",
      element: (
        <AuthenticatedRoute>
          <Login />
        </AuthenticatedRoute>
      ),
    },

    //2
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ),
    },

    //3
    {
      path: "/profile",
      element: (
        <ProtectedRoute>
          <AccountProfile />
        </ProtectedRoute>
      ),
    },

    //4
    {
      path: "/orders",
      element: (
        <ProtectedRoute>
          <Orders />
        </ProtectedRoute>
      ),
    },

    //5
    {
      path: "/orders/:slug",
      element: (
        <ProtectedRoute>
          <OrderDetails />
        </ProtectedRoute>
      ),
    },

    //6
    {
      path: "/returned-orders",
      element: (
        <ProtectedRoute>
          <ReturnedOrders />
        </ProtectedRoute>
      ),
    },

    //7
    {
      path: "/returned-orders/:slug",
      element: (
        <ProtectedRoute>
          <ReturnedOrderDetails />
        </ProtectedRoute>
      ),
    },

    //8
    {
      path: "/payouts",
      element: (
        <ProtectedRoute>
          <Payouts />
        </ProtectedRoute>
      ),
    },

    //9
    {
      path: "/payouts/:slug",
      element: (
        <ProtectedRoute>
          <PayoutDetails />
        </ProtectedRoute>
      ),
    },

    //10
    {
      path: "/logistics-partners",
      element: (
        <ProtectedRoute>
          <LogisticsPartners />
        </ProtectedRoute>
      ),
    },

    //11
    {
      path: "/logistics-partners/:slug",
      element: (
        <ProtectedRoute>
          <LogisticsPartnerDetails />
        </ProtectedRoute>
      ),
    },

    //12
    {
      path: "/support-tickets",
      element: (
        <ProtectedRoute>
          <SupportTickets />
        </ProtectedRoute>
      ),
    },

    //13
    {
      path: "/support-tickets/:slug",
      element: (
        <ProtectedRoute>
          <SupportTicketDetails />
        </ProtectedRoute>
      ),
    },

    //14
    {
      path: "/installers",
      element: (
        <ProtectedRoute>
          <Installers />
        </ProtectedRoute>
      ),
    },

    //15
    {
      path: "/makers",
      element: (
        <ProtectedRoute>
          <Makers />
        </ProtectedRoute>
      ),
    },

    //16
    {
      path: "/makers/:slug",
      element: (
        <ProtectedRoute>
          <MakerContactDetails />
        </ProtectedRoute>
      ),
    },

    //17
    {
      path: "/user-management",
      element: (
        <ProtectedRoute>
          <Users />
        </ProtectedRoute>
      ),
    },

    //18
    {
      path: "/customers",
      element: (
        <ProtectedRoute>
          <Customers />
        </ProtectedRoute>
      ),
    },

    //19
    {
      path: "/customers/:slug",
      element: (
        <ProtectedRoute>
          <CustomerDetails />
        </ProtectedRoute>
      ),
    },

    //20
    {
      path: "/customers/:slug/edit",
      element: (
        <ProtectedRoute>
          <CustomerEditProfile />
        </ProtectedRoute>
      ),
    },

    //21
    {
      path: "/vendors",
      element: (
        <ProtectedRoute>
          <Vendors />
        </ProtectedRoute>
      ),
    },

    //22
    {
      path: "/vendors/:slug",
      element: (
        <ProtectedRoute>
          <VendorDetails />
        </ProtectedRoute>
      ),
    },

    //23
    {
      path: "/products",
      element: (
        <ProtectedRoute>
          <Products />
        </ProtectedRoute>
      ),
    },

    //24
    {
      path: "/products/add",
      element: (
        <ProtectedRoute>
          <AddProduct adding={true}></AddProduct>
        </ProtectedRoute>
      ),
    },

    //25
    {
      path: "/products/:slug",
      element: (
        <ProtectedRoute>
          <AddProduct readOnly={true} />
        </ProtectedRoute>
      ),
    },

    //26
    {
      path: "/products/edit/:slug",
      element: (
        <ProtectedRoute>
          <AddProduct editing={true} />
        </ProtectedRoute>
      ),
    },

    //27
    {
      path: "/auth/login",
      element: (
        <AuthenticatedRoute>
          <Login />
        </AuthenticatedRoute>
      ),
    },

    //28
    {
      path: "/auth/logout",
      element: <Logout />,
    },

    //29
    {
      path: "/auth/reset-password",
      element: <ResetPassword />,
    },
    // 30
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  const filterRouteList = (list) => {
    const routes = list.map((route, index) => {
      return routeList[route - 1];
    });

    return routes;
  };

  function getRouteList() {
    if (isSuperAdmin(user)) {
      return routeList;
    } else if (isAdmin(user)) {
      return filterRouteList([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ]);
    } else if (isCSR(user)) {
      return filterRouteList([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19, 26, 27,
        28, 29, 30,
      ]);
    } else {
      return filterRouteList([1, 2, 26, 27, 28, 29, 30]);
    }
  }

  return {
    routeList: getRouteList(),
  };
}

export default useRouting;
