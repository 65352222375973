import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "../../commons/CustomDatePicker";
import CustomNavTabs from "../../commons/CustomNavTabs";
import Layout from "../../commons/Layout";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import PageTitle from "../../commons/PageTitle";
import { GENERAL_GRAY_HEX } from "../../constants/texts";
import SearchInput from "../../commons/SearchInput";
import useCustomers from "../../hooks/useCustomers";
import CustomersListing from "./CustomersListing";
import useUser from "../../hooks/useUser";
import useDebounce from "./../../hooks/useDebounce";
import useDatePicker from "../../hooks/useDatePicker";

const tabItems = ["All", "Active", "Inactive"];

function Customers() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(tabItems[0]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const { user } = useUser();

  const { DatePickerComponent, startDate, endDate, DateDisplay } =
    useDatePicker(null, "YYYY-MM-DD");

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const { customerItems, isLoading } = useCustomers(
    { accessToken: user?.token },
    pageSize,
    pageNumber,
    selectedTab,
    debouncedSearchTerm,
    [startDate, endDate]
  );

  const handleRowClick = (id) => {
    navigate(`/customers/${id}`);
  };

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left p-6">
        <PageTitle title={"Customers"} />
        <PageBreadCrumb
          mainText="Dashboard"
          subText="Customer List"
          otherText=""
          isActiveIndex={1}
        />
        <div className={`flex justify-between mt-6 text-[${GENERAL_GRAY_HEX}]`}>
          <div>
            <CustomNavTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabItems}
            />
          </div>
          <div className={`flex gap-4`}>
            <SearchInput
              className="bg-white shadow-sm"
              onChange={(e) => setSearchTerm(e)}
              value={searchTerm}
              placeholder={"Search customers..."}
            />
            {DatePickerComponent}
          </div>
        </div>

        {DateDisplay}

        <CustomersListing
          paginationData={{ setPageNumber, pageNumber, pageSize }}
          onRowClick={handleRowClick}
          customers={
            customerItems?.customers
              ? Object.values(customerItems?.customers)
              : []
          }
          isLoading={isLoading}
          totalRecords={customerItems?.totalRecords ?? 10}
        />
      </div>
    </Layout>
  );
}

export default Customers;
