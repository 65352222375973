import React, { useState } from "react";
import Layout from "../../commons/Layout";
import PageTitle from "../../commons/PageTitle";
import { IoIosArrowForward } from "react-icons/io";
import { orders_mock_data } from "../../mock_data/orders";
import OrderRowItem from "../admin_commons/OrderRowItem";
import { GENERAL_GRAY_HEX } from "../../constants/texts";
import OrderTableHeaderList from "../admin_commons/OrderTableHeaderList";
import CustomButton from "./../../commons/CustomButton";
import PageBreadCrumb from "../../commons/PageBreadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import AccountInfo from "../admin_commons/AccountInfo";
import InfoCard from "../admin_dashboard/InfoCard";
import CustomPriceDisplay from "../../commons/CustomPriceDisplay";
import CardTitle from "../admin_commons/CardTitle";
import { Formik } from "formik";
import { Form } from "formik";
import CustomSelectField from "../../commons/CustomSelectField";
import CustomIconLabelValue from "../../commons/CustomIconLabelValue";
import LocationIcon from "../../commons/LocationIcon";
import useCustomer from "./../../hooks/useCustomer";
import CustomNoRecordFound from "./../../commons/CustomNoRecordFound";
import OrdersListing from "../admin_orders/OrdersListing";
import useCustomerOrders from "../../hooks/useCustomerOrders";
import useUser from "../../hooks/useUser";
import CustomIconWithRoundedBg from "../../commons/CustomIconWithRoundedBg";
import GeneralModal from "../../commons/GeneralModal";
import CustomerEditAddress from "./CustomerEditAddress";
import { capitalizeFirstLetter } from "../../helpers/strings";

function CustomerDetails() {
  const { user } = useUser();
  const navigate = useNavigate();
  const params = useParams();

  const pageSize = 10;
  const [pageNumber, setPageNumber] = useState(1);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const handleRowClick = (id) => {
    navigate("/orders/" + id);
  };

  const id = params.slug;

  const { customer } = useCustomer({ id });

  const { userOrderItems } = useCustomerOrders(
    id,
    pageSize,
    pageNumber,
    user?.token
  );

  return (
    <Layout bgClassName="bg-gray-100">
      <div className="text-left py-6 px-3">
        {/* Top */}
        <PageTitle title={"Customers"} />
        <div className="flex justify-between">
          <PageBreadCrumb
            mainText="Dashboard"
            subText="Customers List"
            otherText="Customer Details"
            isActiveIndex={2}
          />

          <div className="flex gap-4">
            <CustomButton
              label="Back"
              onClick={() => navigate(-1)}
              className={`border border-[${GENERAL_GRAY_HEX}] text-[${GENERAL_GRAY_HEX}]`}
            />
            <CustomButton
              label="Edit"
              onClick={() => navigate(`/customers/${id}/edit`)}
              className={`bg-orange-400 border-[${GENERAL_GRAY_HEX}] text-[white]`}
            />
          </div>
        </div>

        <div className="flex justify-between items-start gap-4 w-full">
          {/* Cards */}

          <div className="w-[70%]">
            <div className="flex gap-4 mt-6">
              <InfoCard
                icon={""}
                text={"Total Orders"}
                value={customer?.orders?.length ?? 0}
              />
              <InfoCard
                icon={""}
                text={"Total Spent"}
                value={<CustomPriceDisplay price={customer?.orderTotal} />}
              />
              <InfoCard
                icon={""}
                text={"Refund Balance"}
                value={<CustomPriceDisplay price="0" />}
              />
            </div>
            <div className="p-4 shadow-sm bg-white mt-6 rounded-md w-full">
              <div className="flex justify-between">
                <div className="font-[500] text-[18px]">Order History</div>
              </div>

              {/* Table */}
              <OrdersListing
                paginationData={{ setPageNumber, pageNumber, pageSize }}
                user={{
                  firstName: customer?.firstName,
                  lastName: customer?.lastName,
                }}
                onRowClick={handleRowClick}
                orders={userOrderItems?.orders ?? []}
                totalRecords={userOrderItems?.totalRecords ?? 10}
              />
            </div>
          </div>

          <div className="mt-6 w-[30%]">
            <div className="shadow-sm p-4 bg-white rounded-md text-left mb-6">
              <CardTitle title="Status" />
              <div className="mt-3"></div>
              <Formik>
                {({ isSubmitting, values }) => (
                  <Form>
                    {customer && (
                      <CustomSelectField
                        readOnly={true}
                        disabled={true}
                        selectedValue={customer?.loginStatus}
                        list={[customer?.loginStatus]}
                        name="status"
                        label={
                          <span className="text-[12px]">Customer Status</span>
                        }
                      />
                    )}
                  </Form>
                )}
              </Formik>
            </div>

            <div className="shadow-sm p-4 bg-white rounded-md text-left mb-6">
              <AccountInfo
                {...{
                  ownerLabel: "Customer Name",
                  name: customer?.firstName + " " + customer?.lastName,
                  email: customer?.email,
                  phoneNumber: customer?.phoneNumber,
                  status: capitalizeFirstLetter(customer?.loginStatus),
                  lastLoginDate: customer?.lastLoginDate,
                  loginType: customer?.loginType,
                  topText: "Customer Information",
                }}
              />
            </div>

            <div className="shadow-sm p-4 bg-white rounded-md text-left">
              <CardTitle title="Addresses" />
              {customer?.Addresses?.map((address, index) => {
                return (
                  <div
                    onClick={() => {
                      setSelectedAddress(address);
                      setOpenAddressModal(true);
                    }}
                    className="cursor-pointer mt-2 flex gap-4 items-center w-full text-ellipsis overflow-hidden "
                  >
                    <CustomIconWithRoundedBg>
                      <LocationIcon />
                    </CustomIconWithRoundedBg>
                    <div className="font-[400] text-[14px]">
                      <p className={`text-[${GENERAL_GRAY_HEX}]`}>
                        {"Address " + (index + 1)}
                      </p>
                      <p className="text-[12px]">{address?.address}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <GeneralModal
        widthClass="w-1/2"
        showCloseButton
        isOpen={openAddressModal}
        onClose={() => {
          setOpenAddressModal(false);
          setSelectedAddress(false);
        }}
      >
        <div className="text-left pr-3" style={{ overflowY: "auto" }}>
          <CustomerEditAddress
            address={selectedAddress}
            onClose={() => {
              setOpenAddressModal(false);
              setSelectedAddress(false);
            }}
            customer={customer}
          />
        </div>
      </GeneralModal>
    </Layout>
  );
}

export default CustomerDetails;
