import React from "react";
import StyledSpan from "../../commons/StyledSpan";
import { getTicketStatusName } from "./helpers";

function TicketRowItem({ item, user, onClick = () => {} }) {
  return (
    <>
      <tr class="hover:bg-gray-100 cursor-pointer" onClick={onClick}>
        <td scope="row" class="px-6 py-4 text-[14px] font-[400]">
          #{item?.id}
        </td>
        <td class="px-6 py-4 text-[14px] font-[400]">{item?.fullName}</td>
        <td class="px-6 py-4 text-[14px] font-[400]">{item?.email}</td>
        <td class="px-6 py-4 text-[14px] font-[400]">{item?.phoneNumber}</td>
        <td class="px-6 py-4 text-[14px] font-[400]">{item?.subject}</td>
        <td class="px-6 py-4 flex gap-3">
          <StyledSpan text={getTicketStatusName(item?.status)}></StyledSpan>
        </td>
        <td class="px-6 py-4 text-[14px] font-[400]">
          <StyledSpan
            text={item?.priority ?? "N/A"}
            className={
              item?.priority === "High"
                ? "bg-red-500 text-white"
                : item?.priority === "Medium"
                ? "bg-orange-400 text-white"
                : "bg-green-700 text-white"
            }
          />
        </td>
      </tr>
    </>
  );
}

export default TicketRowItem;
