import { addPaginationUrlQuery } from "../helpers/extractors";
import { getNetworkErrorMessage } from "../helpers/network";
import axiosInstance from "../utils/axiosInstance";

export const getCustomerList = async (
  data,
  pageSize,
  pageNumber,
  status = "",
  searchTerm = "",
  dateRange
) => {
  let url = "/users";

  url = addPaginationUrlQuery(url, pageSize, pageNumber);
  url += `&status=${status}&searchTerm=${searchTerm}&dateFrom=${dateRange[0]}&dateTo=${dateRange[1]}`;

  return axiosInstance(`${url}`, "GET", data).then((response) => {
    return response;
  });
};

export const getSingleCustomer = async (data) => {
  return axiosInstance(`/users/${data?.id}`, "GET", data).then((response) => {
    return response;
  });
};

export const editCustomer = async (data) => {
  return axiosInstance(
    `/auth/users/${data.guid}`,
    "PUT",
    data,
    data.accessToken
  )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      getNetworkErrorMessage(err);
    });
};
